import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueTheMask from 'vue-the-mask'
import money from 'v-money'
import Vuelidate from 'vuelidate'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueCookies from 'vue-cookies'
import VModal from 'vue-js-modal';
import Vuex from 'vuex';

Vue.use(Vuelidate);
Vue.use(VueTheMask);
Vue.use(money);
Vue.use(VueAxios, axios);
Vue.use(VueCookies);
Vue.use(VModal);
Vue.use(Vuex);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
