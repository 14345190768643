<template>
	<modal
		ref="loadingModal"
		name="loading-modal"
		height="auto"
		width="300px"
		:clickToClose="false"
	>
		<div class="content">
			Calculando Valores...
		</div>
	</modal>
</template>

<script>
export default {
	name: 'LoadingModal'
}
</script>

<style lang="scss" scoped>
.content {
	padding: 1rem 2rem;
	display: flex;
	justify-content: center;
	color: $deep-blue;
}
</style>