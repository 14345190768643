<template>
	<div class="simulator-header">
		<div class="logo">
			<img :src="require(`../../assets/img/logos/light-logo.svg`)">
		</div>
		<div
			v-if="maxStep"
			class="progress-bar"
		>
			<div
				class="progress-bar-fill"
				:style="getProgressStyle()"
			/>
		</div>
	</div>
</template>

<script>
export default {
	methods: {
		getProgressStyle () {
			const progress = (this.step / this.maxStep) * 100;

			return `width:${progress}%`;
		}
	},
	props: ['step', 'maxStep']
}
</script>

<style lang="scss" scoped>
.simulator-header {
	width: 100%;

	.logo {
		height: 75px;
		background-color: $deep-blue;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.progress-bar {
		height: 8px;
		width: 100%;
		background-color: #c0d1da;

		.progress-bar-fill {
			height: 100%;
			width: 50%;
			background: $light-blue;
			transition: 0.5s;
		}
	}
}
</style>